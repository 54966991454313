/**
 * Event IDs to be excluded from Grouped Fees experiment
 * and to be cleaned up after the experiment concludes.
 * This was a "quick and dirty" solution due to organisers
 * complaining and not wanting to participate in the test.
 *
 * TODO: Clean up after GROUPED_FEES_EXPERIMENT concludes.
 */
const excludedEventIds = [
  '66ad508cae6ccd14d06fbf75',
  '67d36159018e5e816ffc96b8',
  '67d364d5018e5e816ffd64bd',
  '67650807e9e0e5128a6eeed1',
  '676509afe9e0e5128a6f1b8b',
  '67650a0fe9e0e5128a6f2051',
  '67650a57e9e0e5128a6f2274',
  '67650ac3e9e0e5128a6f2621',
  '67650b11e9e0e5128a6f3038',
  '67650b51e9e0e5128a6f321e',
  '6767334b0c15ea7782eda83c',
  '67bbc3f69457be323205a797',
  '67e86d09ccb6168e444357b0',
  '67eac6ad6e4b74241f029bb1',
  '67ee92acb9e5d524e87b3989',
  '67dcf294053e0307487f0d57',
  '67b7041a1e38578f5075e4a7',
  '67e19f5de1ff4247876d6641',
  '67ecf2df763f4ee676d0a9e6',
  '67d99df9b3a96c3130b077c4',
  '67dca391b58edfe6d28bb571',
  '67eafc7938d13c6c9548907c',
  '67eaa1d5f3e1ce8edb4a406b',
  '676daf244e0e3da79725690a',
  '67c9e284486430b0d84c45e9',
  '67eeb9ab70e4bc90f381eaa9',
  '67eeb9ab70e4bc90f381eaac',
  '67eeb9ab70e4bc90f381eaaf',
]

export {excludedEventIds}
